import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
	Image,
	ImageSearchParams,
	ImageService,
	ImageModalButtonComponent,
} from '@app/models/image';
import { ImageModalService } from './image-modal.service';

@Component({
	selector: 'app-image-modal',
	templateUrl: './image-modal.component.html',
	styleUrls: ['./image-modal.component.css'],
})
export class ImageModalComponent {
	opened: boolean = false;
	images: Image[];
	allImages: Image[];
	private button: ImageModalButtonComponent;

	async ngOnInit(): Promise<void> {
		this.imageModalService.create.subscribe((object) => {
			this.open(object);
		});
		this.allImages = await this.getList();
		this.images = this.allImages;
	}

	public open(component: ImageModalButtonComponent) {
		this.button = component;
		this.opened = true;
	}

	public async search(txt) {
		this.images = this.allImages.filter(function (item) {
			return item
				.getLabel()
				.toLocaleLowerCase()
				.includes(txt.toLocaleLowerCase());
		});
	}

	protected async getList(): Promise<Image[]> {
		const params = new ImageSearchParams({
			_limit: 100,
			_page: 0,
		});

		return (await this.imageService.list(params.toObject())).items;
	}
	public imageSelected(img) {
		console.log(img);
		this.button.imageSelected(img);
		this.opened = false;
	}

	constructor(
		private imageService: ImageService,
		private imageModalService: ImageModalService
	) {}
}
