import { Component, Input } from '@angular/core';
import { ErrorService } from '@app/services';
import { EntityTableComponent } from '@app/abstracts';
import { MultiChoiceQuizModuleSearchParams } from '../multi-choice-quiz-module-search-params';
import { MultiChoiceQuizModuleService } from '../multi-choice-quiz-module.service';
import { MultiChoiceQuizModule } from '../multi-choice-quiz-module';

@Component({
	selector: 'hpf-multi-choice-quiz-module-table',
	templateUrl: './multi-choice-quiz-module-table.component.html',
})
export class MultiChoiceQuizModuleTableComponent extends EntityTableComponent<
	MultiChoiceQuizModule,
	MultiChoiceQuizModuleSearchParams
> {
	/** Columns to show */
	@Input() columns = [
		'name',
		'answers',
		'instructions',
		'categories',
		'created_at',
	];
	/** Constructor */
	constructor(
		private multiChoiceQuizModuleService: MultiChoiceQuizModuleService,
		private errorService: ErrorService
	) {
		super();
	}
	/** @inheritDoc */
	protected refresh(): void {
		const params = this.searchParams.toObject();
		// Force relation population
		params._populate = [
			'instructions',
			'categories.category_image',
			'categories',
		];
		// Set refreshing flag
		this.refreshing = true;
		// Get list
		this.multiChoiceQuizModuleService
			.list(params)
			.then((results) => {
				// Link data
				this.items = results.items;
				this.total = results.total;
			})
			.catch((error) => this.errorService.handle(error))
			.then(() => {
				this.refreshing = false;
			});
	}

	/** Called when the user select multiple rows and delete them */
	async removeMany() {
		if (!this.selectedRows.length) {
			return;
		}

		this.deleting = true;
		for (const item of this.selectedRows) {
			await this.multiChoiceQuizModuleService
				.remove(item.getId())
				.catch((e) => this.errorService.handle(e));
		}

		this.deleteMany.next(this.selectedRows);
		this.resetSelectedRows();
		this.deleting = false;
		this.deleteManyModal = false;
		this.searchParams.next();
	}
	/** Called when the user delete a single row */
	async removeOne() {
		if (!this.rowToDelete) {
			return;
		}

		this.deleting = true;
		await this.multiChoiceQuizModuleService
			.remove(this.rowToDelete.getId())
			.catch((e) => this.errorService.handle(e));

		this.deleteOne.next(this.rowToDelete);
		delete this.rowToDelete;
		this.deleting = false;
		this.deleteOneModal = false;
		this.searchParams.next();
	}
}
