export const environment = {
	production: true,
	appCode: 'ohrizonCmsSmart-admin',
	appVersion: '1',
	languages: ['en', 'fr'],
	appName: 'ohrizonCmsSmart',
	login: {
		redirection: [''],
	},
	logout: {
		redirection: ['session', 'sign-in'],
	},
	api: {
		uri: 'https://api.ohrizoncmssmart.appymax.com/v1',
		adminPath: '/admin',
	},
	images: {
		uri: 'https://s3.appymax.com/ohrizoncmssmart/',
	},
	plugins: {
		quickForm: {
			enabled: true,
		},
	},
};
