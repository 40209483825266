import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import {
	BaseModel,
	BaseModelInterface,
	BaseModelSearchParamsInterface,
} from '@app/abstracts';

/** Interface for listings result */
interface BaseModelSearchResultInterface<T> {
	page: number;
	limit: number;
	count: number;
	total: number;
	items: T[];
}
/** Interface for count results */
interface BaseModelCountResultInterface {
	total: number;
}

interface BaseReadQueryInterface {
	_populate?: string[];
}

@Injectable()
export abstract class BaseModelService<
	T extends BaseModel<BaseModelInterface, P>,
	I extends BaseModelInterface,
	P extends {},
	S extends BaseModelSearchParamsInterface
> {
	/** http Constructor */
	constructor(protected http: HttpClient) {}

	/** Create a new model */
	create(payload: {}, query: BaseReadQueryInterface = {}): Promise<T> {
		// Start request
		const options = {
			withCredentials: true,
			params: query as {},
		};
		if (payload.hasOwnProperty('file')) {
			console.log('create form data');
			payload = this.toFormData(payload);
		}
		return this.http
			.post<I>(`${this.uri()}`, payload, options)
			.toPromise()
			.then((result) => this.new(result));
	}

	/** Update an model selected from it's id */
	update(id: string, payload: {}): Promise<void> {
		// Start request
		console.log(payload);
		const options = { withCredentials: true };
		if (payload.hasOwnProperty('file')) {
			console.log('yoyoyooyo');
			payload = this.toFormData(payload);
		}
		return this.http
			.patch<void>(`${this.uri()}/${id}`, payload, options)
			.toPromise();
	}

	toFormData(payload: {}) {
		const formData = new FormData();
		for (const key in payload) {
			formData.append(key, payload[key]);
		}
		return formData;
	}

	/** Get an model from it's id */
	get(id: string, query: BaseReadQueryInterface = {}): Promise<T> {
		// Start request
		const options = {
			withCredentials: true,
			params: query as {},
		};
		return this.http
			.get<I>(`${this.uri()}/${id}`, options)
			.toPromise()
			.then((result) => this.new(result));
	}
	/** Delete an model selected from it's id */
	remove(id: string): Promise<void> {
		// Start request
		const options = { withCredentials: true };
		return this.http
			.delete<void>(`${this.uri()}/${id}`, options)
			.toPromise();
	}
	/** Get list for model search */
	list(searchParams: S): Promise<BaseModelSearchResultInterface<T>> {
		// Start request
		const options = {
			withCredentials: true,
			params: this.transformSearchParams(searchParams) as {},
		};
		return this.http
			.get<BaseModelSearchResultInterface<I>>(`${this.uri()}`, options)
			.toPromise()
			.then((result) => {
				// Create list from results
				return {
					page: result.page,
					limit: result.limit,
					count: result.count,
					total: result.total,
					items: result.items.map((item): T => this.new(item)),
				};
			});
	}
	/** Count for model */
	count(searchParams: S): Promise<number> {
		// Remove unwanted properties
		const params = Object.assign(
			{},
			this.transformSearchParams(searchParams)
		);
		delete params._page;
		delete params._limit;
		delete params._order;
		delete params._sort;
		// Start request
		const options = {
			withCredentials: true,
			params: params as {},
		};
		return this.http
			.get<BaseModelCountResultInterface>(`${this.uri()}/count`, options)
			.toPromise()
			.then((result) => result.total);
	}
	/** Returns the base URI for this model */
	protected uri(): string {
		return `${environment.api.uri}${
			environment.api.adminPath
		}/${this.path()}`;
	}

	/** Transform search params before search & count */
	protected transformSearchParams(searchParams: S): S {
		return searchParams;
	}

	/** Returns the base URI for this model */
	protected abstract path(): string;
	/** Create a new instance for this payload */
	protected abstract new(object: I): T;
}
